import React, { useState, useEffect, useContext } from "react";
import "../css/header.css";
import { getUserData } from "service/api";
import { AuthContext } from "contexts/AuthContext";
import { handleSignOut } from "service/firebase";

function Header({
  showDropdown = true,
  fetchSuperUser = true,
}) {

  const [isSuperUser, setIsSuperUser] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const displayName = currentUser
    ? currentUser.name || "Username"
    : "Username";

  

  const handleLogout = () => {
    handleSignOut();
  };

  useEffect(() => {
    if (fetchSuperUser) {
      getUserData()
        .then((res) => {
          if (res && res.data) {
            const userData = res.data.data;
            setIsSuperUser(userData.isSuperadmin);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fetchSuperUser]);

  return (
    <>
      <div className="row ml-0 mr-0">
        
        <div className="col-5">
          <a href="/">
            <img
              src="/images/ignite.png"
              width="215"
              height="80"
              alt="Ignite"
            />
          </a>
        </div>
        <div className="col-4 header">ASSET MANAGEMENT</div>
        <div className="col-3 nav-item-container">
          {showDropdown && (
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle"
                  type="button"
                  id="navbarDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {displayName}
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                  x-placement="bottom-end"
                  style={{
                    position: "absolute",
                    willChange: "transform",
                    top: "0px",
                    left: "0px",
                    transform: "translate3d(143px, 40px, 0px)",
                  }}
                >
                  {isSuperUser && (
                    <a
                      href={`${process.env.REACT_APP_API_BASEURL}/admin`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item"
                    >
                      Admin
                    </a>
                  )}

                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Log out
                  </button>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
